$blue: #50c6c3;
$blueHover: #4dd4d0;
$text: #5d5d5d;
$border: #c5c5c5;
$ease: 200ms ease all;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

// @import './../../../node_modules/modern-css-reset/dist/reset.min.css';
// @import './../../../node_modules/include-media/dist/include-media';
@import "../node_modules/@fortawesome/fontawesome-free/css/all.css";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text;
  line-height: 1.5;
  border-top: $blue 0.3em solid;
}
body,
input,
button {
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  padding: 0.6em 1.6em;
  background-color: $blue;
  margin-top: 1em;
  border-radius: 0.4em;
  border: none;
  color: white;
  font-weight: 700;
  cursor: pointer;
  font-size: 1.2em;
  transition: $ease;
  &:hover {
    background-color: $blueHover;
    transform: scale(1.02);
  }
}

input[type="url"],
input[type="text"],
input[type="email"],
input[type="phone"],
textarea {
  border-radius: 0.3em;
  border: 1px solid $border;
  padding: 0.4em 0.5em;
  width: 100%;
  box-sizing: border-box;
}

.wrap {
  max-width: 100%;
  width: 40em;
  margin: 0 auto;
  padding: 0 2em;
  box-sizing: border-box;
}
header {
  margin-top: 4em;
  margin-bottom: 4em;
}
footer {
  margin-top: 4em;
  margin-bottom: 4em;
  .inside {
    text-align: center;
    font-size: 0.85em;
  }
}

.steps {
  .step:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
}

.step {
  padding-bottom: 3em;
  margin-bottom: 3em;
  border-bottom: 0.08em solid #dbdbdb;
}
.step-title {
  color: $blue;
}

.logo {
  color: $blue;
  em {
    font-weight: 400;
    font-style: normal;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  iframe {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
 }
}
.logos {
  margin: 0 -1em;
  a {
    cursor: pointer;
    opacity: 0.5;
    display: inline-block;
    transition: $ease;
    &:hover {
      opacity: 1;
      transform: scale(1.05);
    }
  }
  img {
    padding: 0.7em 1em;
    height: 1.49em;
    width: auto;
  }
}
.works-with {
  text-align: center;
  font-weight: bold;
  margin: 2em 0 3em;
}

a {
  color: $blue;
  &:hover {
    color: $blueHover;
  }
}

.social-icons {
  margin: -0.2em;
  a {
    padding: 0.2em;
    font-size: 2.5em;
    display: inline-block;
    transition: $ease;
    &:hover {
      transform: scale(1.03);
    }
  }
}

.fd-form-group {
  margin-top: 1em;
}

.more-tutorials {
  cursor: pointer;
  margin-left: 1.5em;
  border-bottom: 2px solid $blue;
  text-decoration: none;
  &:hover {
    border-color: $blueHover;
  }
}

.code-wrap {
  display: flex;
  textarea {
    // overflow: ;
    font-size: 0.9em;
  }
  .button.copy {
    margin: 0;
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }
}

.ff-614d4d9dbc3eea86ee42ee07__preference-title {
  font-size: 0.85em;
}
.fd-form-success {
  display: none;
}
